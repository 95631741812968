<!-- 题库管理首页 -->
<template>
  <div>
    <div class="main">
      <div style="font-size: 26px; font-weight: bold;display:flex"><div style="color:#C8C8C8;cursor: pointer;" @click='goback'>&lt;课程管理</div><div>/内容管理</div></div>
      <div>
        <!-- <div> -->
          <el-button
            type="primary"
            style="height: 34px; line-height: 10px; margin-right: 45px"
            class="cinput"
            @click='addcontent'
            >+添加内容</el-button
          >
          <!-- <el-radio-group v-model="radio" style="height: 34px" class="cinput">
            <el-radio-button label="2">视频</el-radio-button>
            <el-radio-button label="3">富文本</el-radio-button>
          </el-radio-group> -->
        <!-- </div> -->
        <div>
          <!-- <el-select
            v-model="value"
            placeholder="层级筛选"
            class="cinput ac"
            style="margin: 0 23px"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select> -->
          <div style="width: 221px">
            <el-input
              v-model="input"
              placeholder="输入内容名称"
              class="cinput ac"
              @keyup.enter.native="list"
            ></el-input>
            <div
              style="
                display: flex;
                height: 34px;
                width: 34px;
                align-items: center;
                background: #f7f7f7;
              "
            >
              <img
                v-if="isshow"
                src="../../images/search2.png"
                alt=""
                @click="list"
                style="background: #f7f7f7"
              />
              <img
                v-if="isshow == false"
                src="../../images/search1.png"
                alt=""
                style="background: #f7f7f7"
              />
            </div>
          </div>
        </div>
      </div>

      <el-table
        :header-cell-style="{ background: '#F7F7F7' }"
        :data="tableData"
        style="width: 100%; min-height: 600px"
         @selection-change="handleSelectionChange"
      >
      <el-table-column
      type="selection"
      align='center'
      width="55"/>
        <el-table-column type="index" width="50"> </el-table-column>
        <el-table-column prop="date"  align="center" v-if="id" width="200">
            <template slot-scope="scope">
                <div>
                   <el-input v-model="scope.row.weight" placeholder='请输入权重' />
                </div>
            </template>
        </el-table-column>
        <el-table-column prop="date"  align="center" >
            <template slot-scope="scope">
                <div>
                    <!-- <video :src="scope.row.videoUrl" class="avatar"  style="width:170px;height:108px">您的浏览器不支持视频播放</video> -->
                    <img :src="scope.row.logoUri" alt="" style="width:170px;height:108px;border-radius:4px">
                </div>
            </template>
        </el-table-column>
        <el-table-column prop="name" align="center" >
        </el-table-column>

        <el-table-column prop="address"  align="center" width="180">
          <template slot-scope="scope">
            <div style="display: flex; justify-content: space-around">
            <el-link type="primary" :underline="false" @click='lookvideo(scope.row)'>详情</el-link>
              <el-link type="primary" :underline="false" v-if="radio=='2'" @click='lookvideo(scope.row)'>查看视频</el-link>
              <el-link type="primary" :underline="false" v-if="radio=='3'" @click='lookcontent(scope.row)'>查看富文本</el-link>
              <el-link type="primary" :underline="false" @click='dele(scope.row)'>删除</el-link>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div
        style="
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top:20px
        "
      >
        <el-button type="primary" @click='deleall' :disabled="isdisabled" v-show="id">加入课程包</el-button>
        <div> </div>
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageIndex"
          :page-sizes="[10, 15, 20]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import {contentpageList,contentsaveOrUpdate2,addContents,pageListNoUse} from '../../apis/index'
export default {
  data() {
    return {
       levelId:'',
      brandId:'',//品牌id
      id:'',//课程包id
      ids:[],//选中的内容id
      total:0,
      pageIndex:1,
      pageSize:10,
      options: [
        {
          value: "选项1",
          label: "黄金糕",
        },
        {
          value: "选项2",
          label: "双皮奶",
        },
      ],
      value: "",
      isshow: false,
      isshow2: true,
      isdisabled:true,
      currentPage: 1,
      radio: "4",
      input: "",
      tableData: [
        
      ],
    };
  },
  watch: {
    input(newdata, olddata) {
      console.log(newdata);
      if (newdata) {
        this.isshow = true;
      } else {
        this.input=''
        this.list()
        this.isshow = false;
      }
    },
    radio(newdata, olddata) {
      this.radio=newdata
      this.pageIndex=1
      this.list()
      // if (newdata == "2") {
      //   this.isshow2 = true;
      // } else {
      //   this.isshow2 = false;
      // }
    },
  },
  created(){
    console.log(this.$route.query)
     let id=this.$route.query.id
    this.brandId=this.$route.query.brandId
    this.levelId=this.$route.query.levelId
    this.id=id
    if(id){
        this.list2()
    }else{
      this.list(id)
    }
  },
  //   beforeRouteLeave(to, from, next) {
  //   if(to.fullPath=='/videoAdd' || to.fullPath=='/contentAdd'){//添加页，就不缓存
  //     this.$route.meta.keepAlive=false
  //     setTimeout(() => {
  //       next()
  //     }, 100);
  //   }else{//详情页缓存
  //   console.log('我是详情缓存')
  //    this.$route.meta.keepAlive=true
  //     setTimeout(() => {
  //       next()
  //     }, 100);
  //   }
  //   // 导航离开该组件的对应路由时调用
  //   // 可以访问组件实例 `this`
  // },
  methods: {
     goback(){
      this.$router.go(-1)
    },
    lookvideo(row){
      this.$router.push({path:'/videoAdd',query:row})
    },
    lookcontent(row){
      this.$router.push({path:'/contentAdd',query:row})
    },
   async list(){//内容管理
      const params={
        pageIndex:this.pageIndex,
        pageSize:this.pageSize,
        contentType:this.radio,
        searchStr:this.input,
        // courseId: this.id,
        // excludeCourseId: this.id
      }
     const {data}=await contentpageList(params)
     if(data.code==200){
       this.tableData=data.data.records,
       this.total=Number(data.data.total)
     }
     console.log(data)
    },
      async list2(){//给排除用的内容列表
      const params={
        // courseId:this.id
        pageIndex:this.pageIndex,
        pageSize:this.pageSize,
        contentType:this.radio,
        searchStr:this.input,
        levelId:this.levelId,
        brandId:this.brandId
      }
     const {data}=await pageListNoUse(params)
     if(data.code==200){
       this.tableData=data.data.records
       this.total=Number(data.data.total)
     }
     console.log(data)
    },
    addcontent(){
      if(this.radio=='3'){
        this.$router.push({path:'/contentAdd'})
      }else{
        this.$router.push({path:'/videoAdd'})
      }
      // this.$router.push({path:'/surveyPapge'})
    },
    search() {
      console.log("我是搜索");
    },
     handleSelectionChange(val) {//选中
        if(val.length>0){
          this.isdisabled=false
        }else{
          this.isdisabled=true
        }
        console.log(val)
      let ids=val.map(item=>{
          return {
            courseId:this.id,
            contentId:item.id,
            weight:item.weight
          }
        })
        this.ids=ids
        // console.log(ids)
      },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageSize=val
      this.list()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.pageIndex=val
      this.list()
    },
     dele(row) {
       console.log(row)
       const params={
         id:row.id,
        //  isValidity:0,
         levelId:row.levelId
       }
        this.$confirm('此操作将永久删除该项, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          contentsaveOrUpdate2(params).then(res=>{
            if(res.data.code==200){
              this.$message({
                type: 'success',
                message: '删除成功!'
              });
              this.list()
            }else{
              this.$message({
                type: 'error',
                message: res.data.msg
              });
            }
          })
          
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });          
        });
      },
       deleall() {
         let tableData=this.tableData
        let ids=tableData.map(item=>{
          return {
            courseId:this.id,
            contentId:item.id,
            weight:item.weight
          }
         })
        let ids2=ids.filter(item=>{
           return item.weight
         })
         this.ids=ids2
        this.$confirm('请确认是否添加选中项, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          const params={
            // contentIds:this.ids,
            // courseId:this.id
            courseContentSaveList:this.ids,
            courseId:this.id
          }
          // console.log(params)
          addContents(params).then(res=>{
            console.log(res)
            if(res.data.code==200){
              this.$message({
              type: 'success',
              message: res.data.msg
            });
            this.list2()
            }else{
              this.$message({
            type: 'info',
            message: res.data.msg
          }); 
            }
          })
          
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '系统正在维修中'
          });          
        });
      }
  },
};
</script>

<style lang='less' scoped>
.main {
  // min-height: 800px;
  background: #fff;
  margin: 19px 0;
  padding: 23px;
  border-radius: 14px;
}
.main > div:first-child {
  font-size: 26px;
  // font-weight: bold;
}
.main > div:nth-child(2) {
  display: flex;
  justify-content: space-between;
  height: 34px;
  margin: 18px 0;
  div {
    display: flex;
    img {
      width: 25px;
      height: 25px;
    }
  }
}
/deep/.el-radio-button__inner {
  height: 34px;
  line-height: 10px;
}
.cinput /deep/.el-input__inner {
  height: 34px;
  line-height: 10px;
}
/deep/.el-button--primary {
  height: 34px; line-height: 10px; 
}
/deep/.el-table::before {
  height: 0;
}
.ac /deep/.el-input__inner {
  border: none;
  background: #f7f7f7;
  // margin-right: 11px;
}
.red {
  color: #ff1b0b;
}
</style>
